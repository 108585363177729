<template>
  <div class="Kontaktpersonen Liste part">
    <div class="constrain">
      <h2 class="title t2" v-html="payload.titel" />
      <div class="personen">
        <div
          v-for="person in payload.personen"
          :key="person.id"
          class="contact-person"
        >
          <div class="image">
            <Image :src="person.bild" />
          </div>
          <person-short :disabled="true" :person="person" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

.Kontaktpersonen {
  color: var(--color-blue);
}

h2 {
  margin-bottom: 2rem;
}

.contact-person {
  position: relative;
  display: flex;
  column-gap: 3rem;
  padding: 1rem 0;
  align-items: center;

  .image {
    @apply rounded-full;

    overflow: hidden;
    position: relative;
    aspect-ratio: 1 / 1;
    flex: 0 0 auto;
    width: 25%;

    filter: grayscale(1);

    :deep(img) {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &::before,
  &:last-child::after {
    @include full-overlay();
    content: "";
    width: 25%;
    height: 1px;
    background: var(--color-blue);
  }

  &:last-child::after {
    bottom: 0;
    top: unset;
  }
}
</style>
